<template>
    <div>
        <a-modal
            :title="!isEdit?'添加试卷':'编辑试卷'"
            :visible="visible"
            :confirmLoading ="loading"
            @cancel="visible = false"
            destroyOnClose
            @ok="submit"
        >
            <a-form v-bind="formItemLayout" :form="form">
                <a-form-item label="试卷名称">
                    <a-input  v-decorator="['name', {initialValue: filter.name, rules: [{ required: true, message: '请输入试卷名!' }] }]" placeholder="请输入试卷名" />
                </a-form-item>
                <a-form-item label="试卷封面" >
                  <a-upload
                      name="avatar"
                      list-type="picture-card"
                      :show-upload-list="false"
                      action=""
                      :customRequest="customRequest"
                  >
                    <img v-if="filter.image" :src="filter.image" alt="封面" />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" style="font-size:30px;color:#dcdcdc"/>
                    </div>
                  </a-upload>
                </a-form-item>
                <a-form-item label="试卷原价" >
                  <a-input-number :precision="2" class="w400"
                                  v-decorator="['cost_price',
                      {
                          initialValue: filter.cost_price,
                          rules: [ { required: true, message: '请输入试卷原价!' },
                      ]}]"  placeholder="请输入试卷原价" />
                </a-form-item>
                <a-form-item label="试卷现价" >
                  <a-input-number :precision="2" class="w400" v-decorator="['now_price',
                      {
                          initialValue: filter.now_price,
                          rules: [ { required: true, message: '请输入试卷现价!' },
                      ]}]"  placeholder="请输入试卷现价" />
                </a-form-item>

                <a-form-item label="选择试卷" >
                    <a-select v-decorator="['task_id', { initialValue: filter.task_id }]" class="mr10px" placeholder="请选择试卷">
                        <a-select-option v-for="item in exams" :key="item.id" :value="item.id">
                            {{item.title}}
                        </a-select-option>
                    </a-select>
                </a-form-item>

            </a-form>
        </a-modal>
        <div class="filter">
            <a-button type="primary" @click="addItem">添加</a-button>
        </div>
        <div class="dragTable">
            <div class="dragHeader">
                <div class="iconTh"></div>
                <div class="flex1">试卷封面</div>
                <div class="flex1">试卷名称</div>
                <div class="flex1">试卷原价</div>
                <div class="flex1">试卷现价</div>
                <div class="operate">操作</div>
            </div>
            <draggable handle=".dragIcon" :list="result" v-if="result.length" @change="log">
                <div v-for="(item, index) in result" :key="index" class="dragWrap">
                    <div class="dragBody border" >
                        <div class="iconTh">
                            <i class="dragIcon"></i>
                        </div>
                        <div class="flex1"><img :src="item.image" alt="image" style="width: 130px"/></div>
                        <div class="flex1">{{item.name}}</div>
                        <div class="flex1">{{item.cost_price}}</div>
                        <div class="flex1">{{item.now_price}}</div>
                        <div class="operate">
                            <i class="z-icon editB" @click="editItem(item)"></i>
                            <i class="z-icon delete" @click="deleteItem(item)"></i>
                        </div>
                    </div>
                </div>
            </draggable>
            <div v-else style="padding:20px 0;text-align:center;background:#fff">
                暂无数据
            </div>
        </div>
    </div>
</template>
<script>
import * as http from '@/libs/examapi'
import {getTaskList, uploadImg} from '@/libs/api'
export default {
    props:['id', 'type'],
    name: 'questionList',
    data(){
        return{
            result: [],
            isEdit: false,
            visible: false,
            //form
            form: this.$form.createForm(this),
            loading: false,
            formItemLayout: {
                labelCol: { span: 4 },
                wrapperCol: { span:18 },
            },
            filter:{
                name: '',
                image: '',
                now_price: '',
                cost_price: '',
                task_id: undefined,
            },
            exams:[]
        }
    },
    watch:{
        id(val){
            this.getExams()
            this.getData()
        },
        type(val){
            if(val){
                this.getData()
            }
        }
    },
    mounted(){
        this.getExams()
        this.getData()
    },
    methods:{
        customRequest(data){
          let formData = new FormData()
          formData.append('file',data.file)
          this.loading = true
          uploadImg(formData).then(res=>{
            this.loading = false
            this.filter.image=res.fileUrl
          },error=>{
            console.log(error)
            this.loading = false
          })
        },
        getExams(){
            getTaskList({detail_type: this.type,user_id:this.$store.state.userInfo.id}).then(res => {
                this.exams = res.list
            })
        },
        getData(){
            this.result = []
            http.getExam({cate_id: this.id}).then(res => {
               this.result = res
            }).catch(error=>{
                console.log(error)
            })
        },
        log(){
            let result = { ids:this.result.map(el=>el.id).join(',')}
            http.orderExam(result).then(res=>{
            }).catch(error=>{
                this.$message.error('拖拽失败，请重新操作')
                this.getProblems()
                console.log(error)
            })
        },
        submit(e){
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    if(!this.isEdit){
                        let request = {...this.filter, ...values, cate_id: this.id}
                        http.addExam(request).then(res => {
                            this.visible = false
                            this.getData()
                        })
                    }else{
                        let request = {...this.filter, ...values, cate_id: this.id}
                        http.editExam(request).then(res => {
                            this.visible = false
                            this.getData()
                        })
                    }
                }
            });

        },
        addItem(){
            this.filter = {
                name: '',
                image: '',
                now_price: '',
                cost_price: '',
                task_id: undefined,
            }
            this.isEdit = false ;
            this.visible = true
        },
        editItem(item){
            this.isEdit = true
            this.filter = JSON.parse(JSON.stringify(item))
            this.filter.task_id = item.task_id? item.task_id:null
            delete this.filter.title
            this.visible = true
        },
        deleteItem({id, name}){
            let _this = this
            this.$confirm({
                title: `确定删除试卷-${name}？`,
                centered:true,
                okText:'确定',
                cancelText:'取消',
                onOk() {
                    http.delExam({id}).then(res => {
                        _this.getData()
                    })
                },
            });
        }

    }
}
</script>
<style scoped>
.filter{
    background: #fff;
    padding: 5px 20px 10px;
}
.w400{
  width: 354px
}
</style>
